@import url('https://s3-ca1.icom.productions/fonts/telus_wise_eco/fonts.css');

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

@-webkit-keyframes fadeInDelayed {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  40% {
    opacity: 0;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeInDelayed {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  40% {
    opacity: 0;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

* {
  -webkit-animation: fadeIn 1s ease;
  animation: fadeIn 1s ease;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Helvetica Neue LT W01_31488866, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
}

body {
  background: transparent;
}

form {
  width: 100%;
}

html {
  height: 100%;
  background-image: url('//s3-ca1.icom.productions/assets/telus/wise/owls-tiny.jpg');
  background-repeat: no-repeat;
  background-color: #fff;
  background-size: cover;
  background-position: 20% 25%;
}

input {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

#footerText {
  margin-top: 20px;
  text-align: center;
}

.greeting {
  display: flex;
  width: 900px;
  text-align: left;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.greetingText {
  padding: 25px 25px 25px 25px;
  width: 70%;
  background-color: #fff;
  margin-left: auto;
  margin-right: 46px;
  text-align: left;
}


#greetingHeader {
  margin-bottom: 24px;
}

.header {
  background-color: #ffffff;
  /* William: Uncomment the following line to add a thick bar to the top of the header */
  border-bottom: 1px solid #e2e2e2;
  /*-webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .3);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .3);*/
  content: '';
  display: block;
  height: 100px;
  left: 0;
  padding: 0 50px;
  position: relative;
  right: 0;
  top: 0;
}

.header .headerIcon {
  position: relative;
  height: 0;
}

.en > .headerLogo{
  height: 7rem;
  width: 23rem;
  background: url('https://s3-ca1.icom.productions/assets/telus/wise/logo-en_110923.png');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 1rem 0 -1.8rem;
}

.fr > .headerLogo{
  height: 7rem;
  width: 23rem;
  background: url('https://s3-ca1.icom.productions/assets/telus/wise/logo-fr_110923.png');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 1rem 0 -1.8rem;
}

.header .languageSelect {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 50px;
  width: auto;
}

.loginForms {
  background: #ffffff;
  margin-bottom: 50px;
}

.loginForms form {
  background-color: #ffffff;
  border: 1px solid #f5f5f5;
  display: inline-block;
  padding: 20px;
  vertical-align: top;
}

.loginForms div,
.loginForms p,
.loginForms button {
  margin-bottom: 8px;
}

.loginForms label {
  text-align: left;
}

.main {
  text-align: center;
}

.loginForms {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  position: relative;
  width: 422px;
}

#telusHeading {
  position: absolute;
  font-size: 64px;
  top: 15px;
  left: 25px;
}

#workshopSelect {
  text-align: left;
}

.TDS_BaseButton-modules__sizing___2kf7G {
  min-width: 184px;
}

@media screen and (max-width: 736px) {
  html {
    background-image: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 100%;
    overflow: scroll;
    width: 100%;
  }

  .greeting {
    display: none;
  }

  .header {
    -webkit-box-shadow: none;
    box-shadow: none;
    display: block;
    height: auto;
    min-height: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    position: static;
    text-align: center;
  }

  .headerLogo{
    margin: 0 !important;
    width: auto !important;
  }

  .header .headerIcon,
  .header .languageSelect {
    top: auto;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    position: static;
  }

  .header .headerIcon {
    background-position: center;
    margin-bottom: 15px;
  }

  .header .headerIcon {
    background-size: contain;
    height: 60px;
  }

  .header .languageSelect label {
    padding: 5px;
  }

  .header.en:after {
    margin: 0;
    padding: 0;
    background-image: none;
    content: '';
  }

  .header.fr:after {
    margin: 0;
    padding: 0;
    background-image: none;
    content: '';
  }

  .loginForms {
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 0;
    padding: 0;
    position: static;
    width: 300px;
  }

  .loginFormsTitle {
    display: none;
  }

  .formInput {
    padding-bottom: 0;
  }

  input[type='submit'] {
    margin-top: 20px;
  }

  #telusHeading {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .view.viewRegistration > .loginForms {
    margin-top: 0;
  }
}
